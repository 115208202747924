import { render, staticRenderFns } from "./SosScreen.vue?vue&type=template&id=3d203248&scoped=true"
import script from "./SosScreen.vue?vue&type=script&lang=js"
export * from "./SosScreen.vue?vue&type=script&lang=js"
import style0 from "./SosScreen.vue?vue&type=style&index=0&id=3d203248&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d203248",
  null
  
)

export default component.exports