<template>
  <content-wrapper class="sos-screen">
    <div class="title">
      <span>
        Звони
        <b>112</b>
      </span>
    </div>

    <div class="subtitle">
      <span>
        Единая диспетчерская
        <br />служба вызова экстренных
        <br />оперативных служб
      </span>
    </div>

    <div class="list">
      <div>
        <span>Полиция</span>
        <span>Скорая помощь</span>
        <span>
          Аварийная
          <br />служба газа
        </span>
      </div>

      <div>
        <span>МЧС</span>
        <span>Пожарная служба</span>
        <span>
          Психологическая
          <br />помощь
        </span>
      </div>
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/Wrappers/ContentWrapper";

export default {
  components: { ContentWrapper }
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.sos-screen {
  background: $grad_red;
  padding: 0 32px;

  .title {
    margin-top: 184px;
    margin-bottom: 80px;
    font-size: 90px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.87px;
    color: #ffffff;
    b {
      font-weight: 500;
      font-size: 112px;
      letter-spacing: -1.08px;
    }
  }

  .subtitle {
    font-size: 60px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.4px;
    color: #ffffff;
    margin-bottom: 144px;
  }

  .list {
    display: flex;
    justify-content: space-between;
    & > div {
      width: 488px;
    }
    span {
      display: block;
      font-size: 40px;
      font-weight: 500;
      line-height: 1.1;
      letter-spacing: -0.32px;
      color: rgba(#fff, 0.97);
      margin-bottom: 24px;
    }
  }
}
</style>
